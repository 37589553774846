import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar,TextField} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import Paper from '@material-ui/core/Paper';
import ReactTable from './shares/react_table';
import classNames from 'classnames';

import {styles} from '../layout/theme';
import Utils from '../api/api';

export class CategoryNewsList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false,
    }
  }
  handleSearch(e){
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  handleAdd(){
    this.context.router.history.push('/newscat/add');
  }
  render(){
    const classes = this.props.classes;
    var source = {
      thead: [{title:'Tiêu đề',value:'nameVN'}, { title: 'Title', value: 'nameEN' }],
      tbody: function (n) {
        return ({
          _id: n._id,
          nameVN: n.nameVN,
          nameEN: n.nameEN
        })
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục nhóm tin tức</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <TextField type="search" id="keyword" label="Tìm kiếm" className={classes.textField + ' form-control-no-bottom'} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}/>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiList='newscats' apiSingle='newscat' apiTable='categorynews' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} >
          </ReactTable>
          {/* <Table className="table-responsive">
            <TableHead>
              <TableRow>
                <TableCell>STT</TableCell>
                <TableCell>Tên Nhóm</TableCell>
                <TableCell>Group Name</TableCell>
                <TableCell>그룹 이름</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map((n,index) => {
                return (
                  <TableRow key={n._id}>
                    <TableCell>{parseInt(this.state.activeStep*config.itemPerPage)+index+1}</TableCell>
                    <TableCell>{n.nameVN}</TableCell>
                    <TableCell>{n.nameEN}</TableCell>
                    <TableCell>{n.nameKR}</TableCell>
                    <TableCell className={classes.right}>
                      <IconButton className={classes.button} aria-label="Cập nhật" onClick={()=>this.handleEdit(n)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton className={classes.button} aria-label="Xóa" onClick={()=>this.handleDelete(n)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <MobileStepper
            variant="dots"
            steps={this.state.totalSteps}
            position="static"
            activeStep={this.state.activeStep}
            nextButton={
              <Button onClick={this.onStepNext} disabled={this.state.activeStep === (this.state.totalSteps-1)}>
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button onClick={this.onStepBack} disabled={this.state.activeStep === 0}>
                <KeyboardArrowLeft />
              </Button>
            }
            className={classes.paging}
          /> */}
        </Paper>
      </div>
    )
  }
}

CategoryNewsList.propTypes = {
  classes: PropTypes.object.isRequired,
};
CategoryNewsList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(CategoryNewsList);

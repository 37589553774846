import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button,Toolbar} from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import FormGroup from '@material-ui/core/FormGroup';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';

import classNames from 'classnames';
import Utils from '../api/api';
import {styles} from '../layout/theme';

class CategoryNewsDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      _id:'',
      category: {
        nameVN: '',
        nameEN: '',
        //nameKR: '',
        urlVN: '',
        urlEN: ''
      },
      error: {
        vn: false,
        en: false,
        //kr: false,
        nameVN_error: false,
        nameEN_error: false,
        //nameKR_error: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      open: false
    }
  }
  componentDidMount(){
    var $this = this;
    var params = this.context.router.route.match.params;

    if(params.id!==undefined){
      this.setState({isUpdate:true},function(){
        Utils.getSingleData('categorynews',params.id,function(res){
          if(res.status=='success'&&res.results!==undefined){
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                data[property] = item[property];
              }
            }
            //data._id = item._id;
            if(data.urlVN===undefined){
              data.urlVN = '';
            }
            if(data.urlEN===undefined){
              data.urlEN = '';
            }
            $this.setState({category: data});
            // $this.setState({category:res.results},function(){
            // });
          }
        });
      });
    }
  }
  handleValidate(e,flag){
    var $this = this,
        category = this.state.category,
        obj = this.state.error;

    category[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + '_error';
    //console.log(p);
    if (e.target.value.trim() == '' || e.target.value == null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    if(e.target.id==='nameVN'){
      var urlVN = Utils.getFriendlyURL(e.target.value);
      category.urlVN = urlVN;
    }
    if(e.target.id==='nameEN'){
      var urlEN = Utils.getFriendlyURL(e.target.value);
      category.urlEN = urlEN;
    }

    this.setState({category: category, error:obj}, () => {
      if(!$this.state.error.nameVN_error){
        obj['vn'] = false;
      }else{
        obj['vn'] = true;
      }
      if(!$this.state.error.nameEN_error){
        obj['en'] = false;
      }else{
        obj['en'] = true;
      }
      // if(!$this.state.error.nameKR_error){
      //   obj['kr'] = false;
      // }else{
      //   obj['kr'] = true;
      // }
      $this.setState({error:obj});
    });
  }
  _save(){
    var $this = this;
    var data = this.state.category;
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('newscat',data, function(res) {
        //console.log(res);
        if (res.status == 'success') {
          $this.context.router.history.push('/newscat/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('newscat',data, function(res) {

        if (res.status == 'success') {
          $this.context.router.history.push('/newscat/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag){
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          obj['nameVN_error'] = this.state.category.nameVN === '';
          obj['vn'] = obj['nameVN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        case 'en':
          obj['nameEN_error'] = this.state.category.nameEN === '';
          obj['en'] = obj['nameEN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        // case 'kr':
        //   obj['nameKR_error'] = this.state.category.nameKR === '';
        //   obj['kr'] = obj['nameKR_error'];
        //   this.setState({error:obj},function(){
        //     if(!$this.state.error[flag]) $this._save();
        //   });
        //   break;
        default:
          return true;
      }
    }
  }
  handleCancel(){
    this.context.router.history.push('/newscat/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  selectImg(value){
    var $this = this,
        obj = this.state.category;
    obj.image = value;
    this.setState({category:obj},function(){
      $this.handleClose();
    });
  }
  render(){
    const classes = this.props.classes;
    const value = this.state.tab;
    return(
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa Nhóm tin tức</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="nameVN" label={language.VN.name} className={classes.textField} value={this.state.category.nameVN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.question_error} margin="normal"/>
            <TextField id="urlVN" label="Link [SEO]" className={classes.textField} value={this.state.category.urlVN} disabled={true} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="nameEN" label={language.EN.name} className={classes.textField} value={this.state.category.nameEN} onChange={(event) => this.handleValidate(event,'en')} onBlur={(event) => this.handleValidate(event,'en')} required={true} error={this.state.error.nameEN_error} margin="normal"/>
            <TextField id="urlEN" label="Link [SEO]" className={classes.textField} value={this.state.category.urlEN} disabled={true} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {/* {value === 2 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.kr && classes.hide)}>{language.KR.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="nameKR" label={language.KR.name} className={classes.textField} value={this.state.category.nameKR} onChange={(event) => this.handleValidate(event,'kr')} onBlur={(event) => this.handleValidate(event,'kr')} required={true} error={this.state.error.nameKR_error} margin="normal"/>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.KR.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'kr')}>{language.KR.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>} */}
      </div>
    )
  }
}

CategoryNewsDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
CategoryNewsDetail.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(CategoryNewsDetail);
